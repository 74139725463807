import moment from 'moment'
import { codeToText as CodeToText } from 'element-china-area-data'
import { ElMessage } from 'element-plus'
import { Decimal } from 'decimal.js'
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime1(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach((v) => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}
/**
 * @param {promise}
 * @return {arr}
 */
export function handlePromise(promise) {
  return promise
    .then((res) => [undefined, res])
    .catch((err) => [err, undefined])
}

/**
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
export function renderTime(date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) {
    return ''
  }
  return moment(date).format(format)
  // var dateee = new Date(date).toJSON();
  // return new Date(+new Date(dateee) + 8 * 3600 * 1000)
  //   .toISOString()
  //   .replace(/T/g, " ")
  //   .replace(/\.[\d]{3}Z/, "");
}

/**
 * @param {object} obj
 * @returns {array}
 */
export function objectToArray(obj) {
  const arr = []
  Object.keys(obj).map((key) => {
    arr.push({ id: key, name: obj[key] })
  })
  return arr
}

/**
 * @param {array} arr
 * @param {array} id
 * @param {array} key id字段名
 * @param {array} value name字段名
 * @returns {string}
 */
export function findNameById(arr, id, key, value) {
  const result = arr.find((item) => item[key] === id)
  return result !== undefined ? result[value] : ''
}
/**
 * @description 根据id获取name,树形结构
 * @param {array} arr
 * @param {number|string} id
 * @param {string} key id字段名
 * @param {string} value name字段名
 * @param {string} value children字段名
 * @returns {string}
 */
export function findTreeNameById(arr, id, key, value, children) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (item[key] === id) {
      return item[value] || ''
    } else {
      if (item[children] && item[children].length > 0) {
        const res = findTreeNameById(item[children], id, key, value, children)
        if (res) {
          return res
        }
      }
    }
  }
}
/**
 * @param {string} codeStr
 * @param {array} codeArray
 * @returns {string}
 */
export function getCodeToText(codeStr, codeArray) {
  if (!codeStr && !codeArray) {
    return null
  } else if (!codeArray) {
    codeArray = codeStr.split(',')
  }
  let area = ''
  switch (codeArray.length) {
    case 1:
      area += CodeToText[codeArray[0]]
      break
    case 2:
      area += CodeToText[codeArray[0]] + '-' + CodeToText[codeArray[1]]
      break
    case 3:
      area +=
        CodeToText[codeArray[0]] +
        '-' +
        CodeToText[codeArray[1]] +
        '-' +
        CodeToText[codeArray[2]]
      break
    default:
      break
  }
  return area
}

/**
 * @description: 根据库房key获取地址
 * @param {string} key
 * @param {string|number} value
 * @param {array} warehouseList
 * @return {string}
 */
export function getWarehouseAddress(key, value, warehouseList) {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return ''
  } else if (value === '') {
    return ''
  } else if (
    Object.prototype.toString.call(warehouseList) !== '[object Array]'
  ) {
    return ''
  }
  const warehouse = warehouseList.find((item) => item[key] === value)
  if (warehouse === undefined) {
    return ''
  }
  const { province_id, city_id, area_id, address } = warehouse
  const result = getCodeToText(`${province_id},${city_id},${area_id}`)
  return `${result} ${address}`
}

/**
 * @description: 查找 arrayA 中不包含 arrayB 的元素
 * @example
 * const arrayA = [1, 2, 3, 4];
 * const arrayB = [2, 3, 5, 6, 7];
 * 返回结果为 [1, 4];
 * @param {Object[]} arrayA
 * @param {Object[]} arrayB
 * @param {string} [key] - 可选参数 object.key
 * @return {Object[]} - 返回一个符合条件的新数组，没有则返回空数组
 */
export function arrayAminusB(arrayA, arrayB, key) {
  return arrayA.filter(
    (eleA) =>
      !arrayB.some((eleB) => {
        if (
          Object.prototype.toString.call(eleA) === '[object Object]' &&
          Object.prototype.toString.call(eleB) === '[object Object]'
        ) {
          return eleB[key] === eleA[key]
        } else {
          return eleB === eleA
        }
      })
  )
}

/**
 * @description: 多层对象的合并
 * @param {*} target
 * @param {*} sources
 * @return {*}
 */
export function assiginObj(target = {}, sources = {}) {
  const obj = target
  if (typeof target !== 'object' || typeof sources !== 'object') {
    return sources
  }
  for (const key in sources) {
    if (Object.prototype.hasOwnProperty.call(target, key)) {
      obj[key] = assiginObj(target[key], sources[key])
    } else {
      obj[key] = sources[key]
    }
  }
  return obj
}

/**
 * @description: 导出文件excel
 * @param {String} file_extension 文件后缀名
 * @param {*} data 文件流
 * @return {*}
 */
export function fileExportProcessing(file_extension, data) {
  // 数据导出有问题，返回参数会有header
  if (data.header) {
    const message = data.header.message
    ElMessage(message ?? '导出失败！')
    return
  }
  ElMessage.success('导出成功')
  const blob = new Blob([data], { type: 'application/octet-stream' }) // 转化为blob对象
  const time = new Date().getTime()
  const timeStr = renderTime(time)
  const filename = timeStr + file_extension // 判断是否使用默认文件名
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL = window.URL.createObjectURL(blob) // 将blob对象转为一个URL
    var tempLink = document.createElement('a') // 创建一个a标签
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename) // 给a标签添加下载属性
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink) // 将a标签添加到body当中
    tempLink.click() // 启动下载
    document.body.removeChild(tempLink) // 下载完毕删除a标签
    window.URL.revokeObjectURL(blobURL)
  }
}
/**
 * @description: 下载模板
 * @param {*} idName
 * @param {*} _this
 * @return {*}
 */
export function downloadTemplate(idName, _this) {
  const tables = document.getElementById(idName)
  const xlsxParam = {
    raw: true
  } // 转换成excel时，使用原始的格式,解决工单号过长导致数字自动转换为科学计数法导致精度缺失
  const table_book = _this.$XLSX.utils.table_to_book(tables, xlsxParam)
  var table_write = _this.$XLSX.write(table_book, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array'
  })
  const time = new Date().getTime()
  const timeStr = renderTime(time)
  try {
    _this.$FileSaver.saveAs(
      new Blob([table_write], { type: 'application/octet-stream' }),
      `数据模板${timeStr}.xlsx`
    )
  } catch (e) {
    if (typeof console !== 'undefined') console.log(e, table_write)
  }
  return table_write
}

/**
 * @description: 数组分组
 * @example arrayGroop(3,[1,2,3,4,5,6,7]) => [[1,2,3],[4,5,6],[7]]
 * @param {number} n - 分组个数
 * @param {Object[]} arr
 * @return {Object[]}
 */
export function arrayGroup(n, arr) {
  const result = []
  for (let i = 0; i < arr.length; i += n) {
    result.push(arr.slice(i, i + n))
  }
  return result
}

/** 文件打包
 * fileList:文件list:[xxx.jpg,xxx.pdf]
 * filename 压缩包名
 * _this this
 * */
export function downloadFile(fileList, filename, _this) {
  const zip = new _this.$JSZip()
  const cache = {}
  const promises = []
  // let reg = /(?<=-file\/).*/g;
  _this.title = '正在加载压缩文件'

  for (const item of fileList) {
    const promise = getImgArrayBuffer(item, _this).then((data) => {
      // 下载文件, 并存成ArrayBuffer对象(blob)
      const index = item.lastIndexOf('/')
      const str = item.substring(index + 1, item.length)
      zip.file(str, data, { binary: true }) // 逐个添加文件
      cache[str] = data
    })
    promises.push(promise)
  }

  Promise.all(promises)
    .then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        _this.title = '正在压缩'
        // 生成二进制流
        _this.$FileSaver.saveAs(content, filename) // 利用file-saver保存文件  自定义文件名
        _this.title = '压缩完成'
      })
    })
    .catch((res) => {
      _this.$message.error('文件压缩失败')
    })
}
// 获取文件blob
export function getImgArrayBuffer(url, _this) {
  // let _this=this;
  return new Promise((resolve, reject) => {
    // 通过请求获取文件blob格式
    const xmlhttp = new XMLHttpRequest()
    xmlhttp.open('GET', url, true)
    xmlhttp.responseType = 'blob'
    xmlhttp.onload = function () {
      if (this.status === 200) {
        resolve(this.response)
      } else {
        reject(this.status)
      }
    }
    xmlhttp.send()
  })
}

/**
 * @description: 分转元
 * @param {number|string} value
 * @return {number|value}
 */
export function fen2yuan(value) {
  if (typeof value === 'number' || typeof value === 'string') {
    const result = new Decimal(value).div(new Decimal(100)).toNumber()
    return result
  } else {
    return value
  }
}

/**
 * @description: 元转分
 * @param {number|string} value
 * @return {number|value}
 */
export function yuan2fen(value) {
  if (typeof value === 'number' || typeof value === 'string') {
    const result = new Decimal(value).mul(new Decimal(100)).toNumber()
    return result
  } else {
    return value
  }
}

/**
 * @description: 乘法运算
 * @param {number|string} value1
 * @param {number|string} value2 百分比
 * @return {number|value}
 */
export function multiplication(value1, value2) {
  if (
    (typeof value1 === 'number' || typeof value1 === 'string') &&
    (typeof value2 === 'number' || typeof value2 === 'string')
  ) {
    const result = new Decimal(value1)
      .mul(new Decimal(value2).div(new Decimal(100)))
      .toNumber()
    return result
  } else {
    return 0
  }
}

// 判断arr是否为一个数组，返回一个bool值
export function isArray(arr) {
  return Object.prototype.toString.call(arr) === '[object Array]'
}

// 深度克隆
export function deepClone(obj) {
  // 对常见的“非”值，直接返回原来值
  if ([null, undefined, NaN, false].includes(obj)) return obj
  if (typeof obj !== 'object' && typeof obj !== 'function') {
    // 原始类型直接返回
    return obj
  }
  var o = isArray(obj) ? [] : {}
  for (const i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      o[i] = typeof obj[i] === 'object' ? deepClone(obj[i]) : obj[i]
    }
  }
  return o
}

<template>
  <el-dialog
    v-model="showDalog"
    title="商品列表"
    width="50%"
    @close="hideGoods"
  >
    <CustomCard :shadow="false">
      <el-form label-width="80px" label-position="left">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="商品名称">
              <el-input placeholder="请输入" clearable style="width: 400px" v-model="formData.product_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label-width="0px">
            <el-input v-model="sku" placeholder="请输入" >
                <template #prepend>
                  <el-select v-model="skuType" placeholder="请选择" style="width: 130px" @change="skuTypeChange">
                    <el-option label="个人买卖SKU" value="1" />
                    <el-option label="自营SKU" value="2" />
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="text-align: right;">
            <el-button type="primary" @click="getList(1)">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        :data="list"
        border
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          'text-align': 'center',
          background: '#fafafa',
          color: '#444'
        }"
        :cell-style="{ 'text-align': 'center' }"
        max-height="600"
      >
        <el-table-column type="selection" width="55" :selectable="v=>v.gd_sku"></el-table-column>
        <el-table-column label="序号" prop="index"></el-table-column>
        <el-table-column label="商品名称" prop="sku_name"></el-table-column>
        <el-table-column label="个人买卖SKU" prop="js_sku"></el-table-column>
        <el-table-column label="自营SKU" prop="gd_sku"></el-table-column>
        <el-table-column label="包装类型" prop="package_type_value"></el-table-column>
        <el-table-column label="建议售价" prop="advice_sale_price_value"></el-table-column>
        <el-table-column label="出售价格阶梯" prop="sale_price_range_value"></el-table-column>
      </el-table>
      <template #bottomOperation>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          v-model:current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          v-model:page-size="formData.page_size"
          layout="total, prev, pager, next, sizes"
          :total="total"
      /></template>
    </CustomCard>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          :disabled="!multipleSelection.length"
          @click="addGoods"
          >批量添加（{{ multipleSelection.length }}）</el-button>
        <el-button @click="hideGoods">返回</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  pageQuerySellerAbsentMemberWineList,
  batchAddMemberWineByWhiteSeller
} from '@/api/jiushi'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      skuType: '1',
      sku: '',
      formData: {
        product_name: '',
        page_index: 1,
        page_size: 10
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      multipleSelection: [],
      showDalog: true
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    list() {
      const list = this.tableData
      list.map((item, index) => {
        item.index = index + 1
        item.advice_sale_price_value = item.advice_sale_price / 100
        item.sale_price_range_value = item.sale_price_range / 100
        if (item.package_type === 1) item.package_type_value = '瓶'
        if (item.package_type === 2) item.package_type_value = '箱'
      })
      return list
    }
  },
  watch: {
    show(val) {
      this.$emit('ctrlDialog', val)
    }
  },
  methods: {
    getList(page) {
      if (page) this.formData.page_index = this.currentPage = page
      const params = {
        ...this.formData,
        [this.skuType === '1' ? 'js_sku' : 'gd_sku']: this.sku
      }
      pageQuerySellerAbsentMemberWineList(params).then((res) => {
        const { data, page_count } = res
        this.tableData = data || []
        this.total = page_count || 0
      })
    },
    sizeChange(size) {
      this.formData.page_index = 1
      this.formData.page_size = size
      this.getList()
    },
    currentChange(currentPage) {
      this.formData.page_index = currentPage
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    addGoods() {
      const list = this.multipleSelection.map((item) => {
        return {
          gd_sku: item.gd_sku,
          js_sku: item.js_sku,
          sku_name: item.sku_name,
          package_type: item.package_type,
          box_specification: item.box_specification
        }
      })
      batchAddMemberWineByWhiteSeller({ list })
        .then((res) => {
          this.$emit('addGoodsSuccess')
        })
        .catch((res) => {
          this.$message.error('批量添加失败，请稍后重试或联系管理员')
        })
    },
    hideGoods() {
      this.$emit('ctrlDialog', false)
    },
    skuTypeChange() {
      this.sku = ''
    }
  }
}
</script>

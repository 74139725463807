<!--
 * @Author: goodLiang
 * @Date: 2023-12-04 17:42:22
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-18 10:28:03
 * @FilePath: /business-cms/src/views/manage/goods/list.vue
-->
<template>
  <div class="app-container">
    <CustomCard>
      <FoldPanel>
        <el-form label-width="110px" label-position="left">
          <el-row>
            <GridItem>
              <el-form-item label="商品名称">
                <el-input
                  placeholder="请输入"
                  clearable
                  v-model="formData.product_name"
                ></el-input>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="上下架状态">
                <el-select
                  clearable
                  placeholder="请选择"
                  v-model="formData.pop_if_up"
                >
                  <el-option label="全部" :value="undefined"></el-option>
                  <el-option label="上架" :value="1"></el-option>
                  <el-option label="下架" :value="0"></el-option>
                  <el-option label="部分上下架" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
          </el-row>
        </el-form>
        <template #operation>
          <el-button type="primary" @click="getList(1)">查询</el-button>
        </template>
      </FoldPanel>
      <el-divider></el-divider>
      <div class="btn_box">
        <div>
          <el-button @click="exportFile">批量导出</el-button>
          <el-button @click="updateFile">批量修改价格</el-button>
          <el-button style="margin: 0 10px" @click="upOrDownGoods(2, null, 1)"
            >批量上架</el-button
          >
          <el-button @click="upOrDownGoods(2, null, 0)">批量下架</el-button>
          <el-button
            @click="updateStoreCount = true"
            v-if="$store.getters.userInfo.business_mode == 2"
            >批量修改库存</el-button
          >
          <el-button
            type="primary"
            v-if="$store.getters.userInfo.business_mode == 2"
            @click="dialogFormVisible = true"
            >添加商品</el-button
          >
        </div>
      </div>
      <div class="table-box">
        <el-table
          :data="list"
          border
          @selection-change="handleSelectionChange"
          :header-cell-style="{
            'text-align': 'center',
            background: '#fafafa',
            color: '#444'
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" prop="index"></el-table-column>
          <el-table-column label="商品编码" prop="sku"></el-table-column>
          <el-table-column
            label="商品名称"
            prop="product_name"
          ></el-table-column>
          <el-table-column
            label="包装类型"
            prop="package_type_value"
          ></el-table-column>
          <el-table-column label="库存" v-slot="scope">
            <el-link
              type="primary"
              @click="handleGoodsDetail(scope.row.sku, 1)"
            >
              {{ scope.row.product_quality }}
            </el-link>
          </el-table-column>
          <el-table-column
            label="上下架状态"
            prop="pop_if_up_value"
          ></el-table-column>
          <el-table-column label="指导价格" v-slot="scope">
            <el-link
              type="primary"
              @click="handleGoodsDetail(scope.row.sku, 2)"
            >
              {{ scope.row.now_price }}
            </el-link>
          </el-table-column>
          <el-table-column label="出售价格阶梯" v-slot="scope">{{
            scope.row.sale_price_range / 100
          }}</el-table-column>
          <el-table-column label="当前售价" v-slot="scope">
            <el-link
              type="primary"
              @click="handleGoodsDetail(scope.row.sku, 3)"
            >
              {{ scope.row.pop_sale_price }}
            </el-link>
          </el-table-column>
          <el-table-column
            label="修改时间"
            prop="update_time"
          ></el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="200"
            v-slot="scope"
          >
            <el-link type="primary" @click="toEdit(scope.row)">编辑</el-link>
            <el-link
              type="primary"
              :disabled="scope.row.admin_if_up === 0"
              v-show="scope.row.pop_if_up === 0 || scope.row.pop_if_up === 2"
              @click="upOrDownGoods(1, scope.row.sku, 1)"
              >上架</el-link
            >
            <el-link
              type="primary"
              v-show="scope.row.pop_if_up === 1 || scope.row.pop_if_up === 2"
              @click="upOrDownGoods(1, scope.row.sku, 0)"
              >下 架</el-link
            >
            <el-link
              type="primary"
              v-show="scope.row.pop_if_up === 0 || scope.row.pop_if_up === 2"
              @click="removeGoods(scope.row.sku)"
              >移除</el-link
            >
          </el-table-column>
        </el-table>
      </div>
      <template #bottomOperation>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          v-model:current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          v-model:page-size="formData.page_size"
          layout="total, prev, pager, next, sizes"
          :total="total"
        />
      </template>
    </CustomCard>
    <div v-if="dialogFormVisible">
      <Dialog @addGoodsSuccess="addGoodsSuccess" @ctrlDialog="ctrlDialog" />
    </div>
    <el-dialog title="批量修改" v-model="updateDialog" width="25%">
      <div style="text-align: center">
        <el-button
          style="margin-bottom: 20px"
          type="primary"
          @click="downloadTemplate(1)"
          >下载模版</el-button
        >
        <Upload
          :uploadUrl="'/gdyx/file/system/price/batchUpdateSellerProductPrice'"
          @uploadSuccess="uploadSuccess"
        />
      </div>
    </el-dialog>
    <el-dialog title="批量修改库存" v-model="updateStoreCount" width="25%">
      <div style="text-align: center">
        <el-button
          style="margin-bottom: 20px"
          type="primary"
          @click="downloadTemplate(2)"
          >下载模版</el-button
        >
        <Upload
          :uploadUrl="'/gdyx/file/system/memberWineStock/batchUpdatePopWineStock'"
          @uploadSuccess="uploadFileSuccess"
        />
      </div>
    </el-dialog>
    <el-dialog
      :title="['', '库存明细', '指导价格明细', '当前售价明细'][stockViewType]"
      v-model="stockDialog"
      width="55%"
    >
      <div style="max-height: 600px; overflow: auto">
        <el-table
          :data="stockList"
          border
          :header-cell-style="{
            'text-align': 'center',
            background: '#fafafa',
            color: '#444'
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column label="类型" prop="stock_type"></el-table-column>
          <el-table-column label="唯一码" prop="unique_code"></el-table-column>
          <el-table-column
            label="数量"
            prop="stock"
            v-if="stockViewType === 1"
          ></el-table-column>
          <el-table-column
            label="指导价格"
            v-slot="scope"
            v-if="stockViewType === 2"
            >{{ scope.row.now_price / 100 }}({{
              scope.row.now_price_source_name
            }})</el-table-column
          >
          <el-table-column
            label="当前售价"
            v-slot="scope"
            v-if="stockViewType === 3"
            >{{ scope.row.pop_sale_price / 100 }}</el-table-column
          >
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fileExportProcessing } from '@/utils/index.js'
import Dialog from './dialog.vue'
import Upload from './upload.vue'
import {
  pageQuerySellerMemberWineList,
  batchUpdateMemberWineStatusByWhiteSeller,
  exportPopSellerProductList,
  selectPopMemberWineItem
} from '@/api/jiushi'

export default {
  components: {
    Dialog,
    Upload
  },
  data() {
    return {
      formData: {
        product_name: '',
        pop_if_up: undefined,
        page_index: 1,
        page_size: 10
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      multipleSelection: [],
      dialogFormVisible: false,
      updateDialog: false,
      updateStoreCount: false,
      stockDialog: false,
      stockList: [],
      stockViewType: ''
    }
  },
  mounted() {
    this.getList()

    console.log(this.$store.getters.userInfo)
  },
  computed: {
    list() {
      const list = this.tableData
      list.map((item, index) => {
        item.index = index + 1
        item.now_price_value = item.now_price / 100
        item.sale_price_range_value = item.sale_price_range / 100
        item.pop_sale_price_value = item.pop_sale_price / 100
        if (item.package_type === 1) item.package_type_value = '瓶'
        if (item.package_type === 2) item.package_type_value = '箱'
        if (item.pop_if_up === 2) item.pop_if_up_value = '部分上架'
        if (item.pop_if_up === 1) item.pop_if_up_value = '上架'
        if (item.pop_if_up === 0) item.pop_if_up_value = '下架'
      })
      return list
    }
  },
  methods: {
    getList(page) {
      if (page) this.formData.page_index = this.currentPage = page
      pageQuerySellerMemberWineList({ ...this.formData }).then((res) => {
        const { data, page_count } = res
        this.tableData = data || []
        this.total = page_count || 0
      })
    },
    getStock(sku) {
      const params = {
        sku,
        page_index: 1,
        page_size: 10000
      }
      selectPopMemberWineItem(params).then((res) => {
        this.stockList = res.data
      })
    },
    handleGoodsDetail(sku, type) {
      this.stockDialog = true
      this.stockViewType = type
      this.getStock(sku)
    },
    sizeChange(size) {
      this.formData.page_index = 1
      this.formData.page_size = size
      this.getList()
    },
    currentChange(currentPage) {
      this.formData.page_index = currentPage
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    exportFile() {
      const data = {}
      if (!this.multipleSelection.length) {
        data.product_name = this.formData.product_name || ''
        data.pop_if_up = this.formData.pop_if_up || null
      }
      if (this.multipleSelection.length) {
        data.member_wine_stock_ids = this.multipleSelection.map(
          (item) => item.id
        )
      }
      exportPopSellerProductList({ ...data }).then((res) => {
        console.log('res :>> ', res)
        fileExportProcessing('.xlsx', res)
      })
    },
    updateFile() {
      this.updateDialog = true
    },
    downloadTemplate(type) {
      if (type === 1) {
        window.open(
          'https://googutwine.oss-cn-beijing.aliyuncs.com/gede-product/updateSellerWineStockPrice.xlsx'
        )
      }
      if (type === 2) {
        window.open(
          'https://googutwine.oss-cn-beijing.aliyuncs.com/gede-product/%E6%89%B9%E9%87%8F%E4%BF%AE%E6%94%B9%E5%BA%93%E5%AD%98%E6%A8%A1%E6%9D%BF.xlsx'
        )
      }
    },
    uploadSuccess() {
      this.updateDialog = false
      this.getList(1)
    },
    uploadFileSuccess() {
      this.updateStoreCount = false
      this.getList(1)
    },
    upOrDownGoods(action, sku, up_shelf) {
      let list = []
      // 单个操作
      if (action === 1) list.push({ up_shelf, sku })
      // 批量操作
      if (action === 2 && !this.multipleSelection.length) {
        this.$message.warning('请先进行勾选，再进行批量操作')
        return
      }
      if (action === 2 && this.multipleSelection.length) {
        list = this.multipleSelection.map((item) => {
          return { up_shelf, sku: item.sku }
        })
      }
      batchUpdateMemberWineStatusByWhiteSeller({
        operate_type: up_shelf,
        list
      }).then((res) => {
        this.$message.success('操作成功')
        this.getList()
      })
    },
    toEdit(data) {
      this.$router.push({
        path: '/manage/goods/detail',
        query: data
      })
    },
    removeGoods(sku) {
      const list = [{ remove_flag: 1, sku }]
      this.$confirm('是否移除此商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          batchUpdateMemberWineStatusByWhiteSeller({
            operate_type: 2,
            list
          }).then((res) => {
            this.$message.success('移除成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    addGoodsSuccess() {
      this.$message.success('批量添加成功')
      this.getList(1)
      this.dialogFormVisible = false
    },
    ctrlDialog(val) {
      this.dialogFormVisible = val
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 80% !important;
}

.btn_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>

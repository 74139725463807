<!--
 * @Author: goodLiang
 * @Date: 2023-10-16 10:02:35
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-12 15:26:06
 * @FilePath: /vue3-template/src/views/manage/goods/upload.vue
-->
<template>
  <el-upload
    class="avatar-uploader"
    :http-request="customUpload"
    :before-remove="beforeRemove"
    :on-remove="handleRemove"
    :limit="1"
    :file-list="fileList"
  >
    <i class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    uploadUrl: {
      type: String,
      default: '/gdyx/file/system/price/batchUpdateSellerProductPrice'
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  methods: {
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.fileList = []
    },
    customUpload(fileObj) {
      const formData = new FormData()
      formData.append('file', fileObj.file)
      formData.append('token', this.$store.getters.token)
      return axios
        .request({
          url: this.uploadUrl,
          method: 'post',
          data: formData,
          timeout: 60000
        })
        .then((res) => {
          const { res_code, message } = res.data.header
          const { body } = res.data
          if (res_code === 0) {
            this.$message.success(body)
          } else {
            this.$message.error(message)
          }
          this.fileList = []
          this.$emit('uploadSuccess')
        })
        .catch((error) => {
          const errorMsg = JSON.stringify(error)
          if (errorMsg.indexOf('timeout') !== -1) {
            this.loading = false
            this.$message.error('请求超时，请联系管理员')
          }
        })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #0071db;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
